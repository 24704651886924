<template>
  <groups-list />
</template>

<script>
import GroupsList from '@/views/schools/groups/List.vue'
export default {
  components:{
    GroupsList
  }
}
</script>

<style>

</style>