<template>
  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      @refetch-data="refetchData"
    />
    <user-list-add-new-dreamer
      :is-add-new-user-sidebar-active.sync="isAddNewDreamerSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    />

    <modal-personalize></modal-personalize>
    <modal-content-restriction></modal-content-restriction>
    <modal-children-passwords></modal-children-passwords>
    <modal-massive
      from="grupos"
    ></modal-massive>
    
    
    <!-- content-header -->
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="9"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Groups
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item 
                :active="false">
                  <feather-icon
                    icon="SquareIcon"
                    size="18"
                    class="align-text-top"
                    style="margin-right: 5px;"
                  />
                  Select All
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right d-md-block  mb-1"
        md="3"
        cols="3"
      >
        <b-dropdown
          variant="link"
          no-caret
          toggle-class="p-0"
          right
        >

          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>
                  <b-dropdown-item
                  @click="isAddNewUserSidebarActive = true"
                  >
                      <feather-icon
                          icon="CheckSquareIcon"
                          size="16"
                      />
                      <span class="align-middle ml-50">Añadir Grupo</span>
                  </b-dropdown-item>
                  <b-dropdown-divider />

                  <b-dropdown-item @click="$bvModal.show('bv-modal-personalize')">
                      <feather-icon
                          icon="CheckSquareIcon"
                          size="16"
                      />
                      <span class="align-middle ml-50"
                      >
                          Personalizar
                      </span>
                  </b-dropdown-item>
                  
                  
                  <b-dropdown-item @click="$bvModal.show('bv-modal-content-restriction')">
                      <feather-icon
                          icon="CheckSquareIcon"
                          size="16"
                      />
                      <span class="align-middle ml-50">Restringir Contenido</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="$bvModal.show('bv-modal-children-passwords')">
                      <feather-icon
                          icon="CheckSquareIcon"
                          size="16"
                      />
                      <span class="align-middle ml-50">Gestionar Contraseñas</span>
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item  @click="$bvModal.show('bv-modal-massive')">
                      <feather-icon
                          icon="CheckSquareIcon"
                          size="16"
                      />
                      <span class="align-middle ml-50">Carga Masiva con Excel</span>
                  </b-dropdown-item>
                  <b-dropdown-item>
                      <feather-icon
                          icon="CheckSquareIcon"
                          size="16"
                      />
                      <span class="align-middle ml-50">Descargar listados por grupo</span>
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item>
                      <feather-icon
                          icon="CheckSquareIcon"
                          size="16"
                      />
                      <span class="align-middle ml-50" style="color:red;">Eliminar</span>
                  </b-dropdown-item>


        </b-dropdown>
      </b-col>
    </b-row>


    <b-row class="match-height">
        <b-col
            lg="3"
            md="6"
            v-for="group in groups"
            v-bind:key="group.id"
        >
            <b-card
                no-body
                class="card-developer-meetup"
            >
                <div class="bg-light-primary rounded-top text-center">
                <b-img
                    :src="group.img"
                    alt="Meeting Pic"
                    style="width:50%"
                />
                </div>
                <b-card-body>
                <!-- metting header -->
                <div class="meetup-header d-flex align-items-center justify-content-between" style="width: 100%;">
                  <div class="">
                    <b-form-checkbox
                      :checked="false"
                    />
                  </div>
                  <b-card-title class="mb-25">
                      {{ group.id}}º Primaria NEE
                  </b-card-title>
                  <!-- <feather-icon
                      icon="MoreVerticalIcon"
                      size="18"
                      class="cursor-pointer"
                  /> -->
                  <b-nav-item-dropdown
                      id="my-nav-dropdown"
                      text=""
                      toggle-class="nav-link-custom"
                      right
                      >
                      <b-dropdown-item @click="isAddNewUserSidebarActive = true">Editar</b-dropdown-item>
                      <b-dropdown-item @click="isAddNewDreamerSidebarActive = true">Añadir Niño</b-dropdown-item>
                      <b-dropdown-item @click="$bvModal.show('bv-modal-personalize')">Personalizar</b-dropdown-item>
                      <b-dropdown-item @click="$bvModal.show('bv-modal-content-restriction')">Restringir Contenido</b-dropdown-item>
                      <b-dropdown-item @click="$bvModal.show('bv-modal-children-passwords')">Gestionar Contraseñas</b-dropdown-item>
                      <b-dropdown-divider />
                      <b-dropdown-item><span style="color:red;">Eliminar</span></b-dropdown-item>
                  </b-nav-item-dropdown>
                    
                </div>
                <!--/ metting header -->

                <!-- media -->
                <!-- <b-media
                    v-for="media in mediaData"
                    :key="media.avatar"
                    no-body
                >
                    <b-media-aside class="mr-1">
                    <b-avatar
                        rounded
                        variant="light-primary"
                        size="34"
                    >
                        <feather-icon
                        :icon="media.avatar"
                        size="18"
                        />
                    </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                    <h6 class="mb-0">
                        {{ media.title }}
                    </h6>
                    <small>{{ media.subtitle }}</small>
                    </b-media-body>
                </b-media> -->

                <!-- avatar group -->
                <b-avatar-group
                    class="mt-2 pt-50 mb-1"
                    size="33"
                >
                    <b-avatar
                    v-for="avatar in avatars"
                    :key="avatar.avatar"
                    v-b-tooltip.hover.bottom="avatar.fullName"
                    :src="avatar.avatar"
                    class="pull-up"
                    />
                    <h6 class="align-self-center cursor-pointer ml-1 mb-0">
                    +42 Niños
                    </h6>
                </b-avatar-group>


                </b-card-body>
            </b-card>
        </b-col>
        
    </b-row>

  </div>
    
</template>

<script>
import UserListAddNew from './UserListAddNew.vue'
import UserListAddNewDreamer from '../dreamers/UserListAddNew.vue'

import {
  BCard, BCardText, BButton, BRow, BCol, BImg, BCardBody, BCardTitle, BCardSubTitle, BLink,
  BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody,
  BDropdown, BDropdownItem, BDropdownDivider,
  BNav, BNavItem, BNavItemDropdown,
  BFormCheckbox,
  BFormGroup, BFormInput, BForm, BFormRadio,
  BBreadcrumb, BBreadcrumbItem, 
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ModalPersonalize from '../modals/ModalPersonalize.vue'
import ModalContentRestriction from '../modals/ModalContentRestriction.vue'
import ModalChildrenPasswords from '../modals/ModalChildrenPasswords.vue'
import ModalMassive from '../modals/ModalMassive.vue'

export default {
  components: {
    UserListAddNew, 
    UserListAddNewDreamer,
    ModalPersonalize,
    ModalContentRestriction,
    ModalChildrenPasswords,
    ModalMassive,

    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody,
    BDropdown, BDropdownItem, BDropdownDivider,
    BNav, BNavItem, BNavItemDropdown,
    BFormCheckbox,
    BFormGroup, BFormInput, BForm, BFormRadio,
    BBreadcrumb, BBreadcrumbItem, 
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isAddNewUserSidebarActive: false, 
      isAddNewDreamerSidebarActive: false, 

      avatars: [
        { avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_51.png?v3', fullName: 'Billy Hopkins' },
        { avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_41.png?v3', fullName: 'Amy Carson' },
        { avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_31.png?v3', fullName: 'Brandon Miles' },
        { avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_21.png?v3', fullName: 'Daisy Weber' },
        { avatar: 'https://library.smileandlearn.net/resources/img/avatar/AvatarSprite_11.png?v3', fullName: 'Jenny Looper' },
      ],
      groups : [
          {
              id:1,
              name: "1º Primaria A",
              img: 'https://library.smileandlearn.net/resources/img/avatar/groups/7.png'
          },
          {
              id:2,
              name: "1º Primaria B",
              img: 'https://library.smileandlearn.net/resources/img/avatar/groups/5.png'
          },
          {
              id:3,
              name: "2º Primaria",
              img: 'https://library.smileandlearn.net/resources/img/avatar/groups/4.png'
          },
          {
              id:4,
              name: "3º Primaria",
              img: 'https://library.smileandlearn.net/resources/img/avatar/groups/3.png'
          },
          {
              id:5,
              name: "4º Primaria",
              img: 'https://library.smileandlearn.net/resources/img/avatar/groups/2.png'
          },
          {
              id:6,
              name: "5º Primaria",
              img: 'https://library.smileandlearn.net/resources/img/avatar/groups/1.png'
          },
      ],
      Selected: false,
      roleOptions : [
        { label: 'Seleccione...', value: null },
        { label: '1º A Primaria', value: '1APrimaria' },
        { label: '1º B Primaria', value: 'author' },
        { label: '2º A Primaria', value: 'editor' },
        { label: '3º A Primaria', value: 'maintainer' },
        { label: 'NEE', value: 'subscriber' },
      ],
      planOptions : [
        { label: 'Seleccione...', value: null },
        { label: '0-5', value: 'basic' },
        { label: '6-7', value: 'company' },
        { label: '8-9', value: 'enterprise' },
        { label: '10-12', value: 'team' },
      ],
    }
  },
  methods:{
    refetchData(){
      console.log("recargar data")
    }
  }
}
</script>

<style>
#my-nav-dropdown{
    list-style-type:none;
}


</style>
