<template>
  <section id="dashboard-analytics" >
    <groups-tic v-if="userData.role == 'school-admin'" />
    <groups-general v-else />
  </section>
</template>

<script>
import GroupsTic from './GroupsTIC.vue'
import GroupsGeneral from './GroupsGeneral.vue'
export default {
  components:{
    GroupsTic,
    GroupsGeneral

},
  data(){
    return {
      userData: this.$store.getters['user/getUserData']
    }
  }
}
</script>

<style>

</style>